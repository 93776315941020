export default (props: any) => (
  <svg width="190" height="191" viewBox="0 0 190 191" fill="none" {...props}>
    <path
      d="M0 20C0 8.9543 8.95431 0 20 0H66C77.0457 0 86 8.95431 86 20V86H20C8.9543 86 0 77.0457 0 66V20Z"
      fill="#04D1FF"
    />
    <path
      d="M108 111H170C178.837 111 186 118.163 186 127V171C186 179.837 178.837 187 170 187H124C115.163 187 108 179.837 108 171V111Z"
      stroke="#fff"
      stroke-width="8"
    />
    <path
      d="M0 125C0 113.954 8.95431 105 20 105H86V171C86 182.046 77.0457 191 66 191H20C8.9543 191 0 182.046 0 171V125Z"
      fill="#FF00B3"
    />
    <path
      d="M104 20C104 8.9543 112.954 0 124 0H170C181.046 0 190 8.95431 190 20V66C190 77.0457 181.046 86 170 86H104V20Z"
      fill="#22BC97"
    />
  </svg>
);
