import React, { useEffect } from "react";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components";
import LightState from "react-light-state";
import { OPService } from "../../services/OnePointService";

interface IApp {
    title: string;
    description: string;
    image_url: string;
    url: string;
}

const store = new LightState({ apps: [] }, "apps", {
    storageName: "fozg-apps",
});

export default function () {
    const apps: any = store.useStore((state) => state.apps);
    useEffect(() => {
        fetch();
    }, []);
    const fetch = async () => {
        const res = await OPService()
            .getListByName("apps")
            .getItems();
            
        if (!res.isError) {
            store.setState({ apps: res.data });
        }
    };

    return (
        <Box width={360}>
            <Box ml={15}>
                <Text fontWeight={500} fontSize={15}>
                    More apps
                </Text>
            </Box>
            <Flex flexWrap="wrap" padding={10}>
                {apps.map((app: IApp) => (
                    <AppItem href={app.url} target="_blank">
                        <Image src={app.image_url} />
                        <Text fontWeight={500} ml={2} fontSize={".85em"}>
                            {app.title}
                        </Text>
                    </AppItem>
                ))}
            </Flex>
        </Box>
    );
}

const Image = styled.img`
    max-width: 40px;
    height: 40px;
`;

const AppItem = styled.a`
    padding: 10px;
    display: flex;
    // flex-direction: column;
    align-items: center;
    // justify-content: center;
    text-align: center;
    width: 50%;
    box-sizing: border-box;
    border-radius: 10px;
    text-decoration: none;
    :visited {
        color: #000;
    }
    :hover {
        cursor: pointer;
        box-shadow: 0 0px 0px 2px rgba(100, 100, 100, 0.25);
        background: rgba(0, 0, 0, 0.1);
    }
`;
