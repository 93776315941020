import { ReactElement } from "react";
import ReactDOM from "react-dom";
import { ListMircroApps } from "../services/OnePointService";
import { getState } from "../state";

interface RegisteredApp {
  appId: string;
  root: ReactElement;
  liveTitle: ReactElement;
  renderLiveTitle: (elementId: string) => void;
  renderRoot: (elementId: string) => void;
  globalActions: any;
}

class AppManager2 {
  private apps: any = {};

  register({
    appId,
    root,
    liveTitle,
    renderRoot,
    renderLiveTitle,
    globalActions,
  }: {
    appId: string;
    root: ReactElement;
    liveTitle: ReactElement;
    renderRoot: any;
    renderLiveTitle: any;
    icon_url?: string;
    globalActions: any;
  }) {
    this.apps[appId] = {
      appId,
      root,
      liveTitle,
      renderLiveTitle,
      renderRoot,
      globalActions,
    };
  }

  private async getScriptRender(
    appId: string,
    bundleUrl?: string
  ): Promise<RegisteredApp> {
    if (this.apps[appId]) {
      return this.apps[appId]!;
    } else {
      var src = bundleUrl ? bundleUrl : await getScriptPath(appId);
      await loadScript(src);
      return this.apps[appId]!;
    }
  }

  public async action({
    appId,
    action,
    bundleUrl,
    payload,
  }: {
    appId: string;
    action: string;
    bundleUrl?: string;
    payload?: any;
  }) {
    const scripts = await this.getScriptRender(appId, bundleUrl);
    console.log({ scripts });
    if (scripts.globalActions && scripts.globalActions[action]) {
      return scripts.globalActions[action](payload);
    }
  }

  public async renderRoot({
    appId,
    bundleUrl,
    element,
  }: {
    appId: string;
    bundleUrl?: string;
    element: HTMLElement;
  }) {
    // ReactDOM.render(<LoadingRoot />, document.getElementById(`root-${appId}`));
    const scripts = await this.getScriptRender(appId, bundleUrl);
    if (!scripts || !scripts.root)
      return ReactDOM.render(<h3>App Not found</h3>, element);

    if (scripts.renderRoot) {
      scripts.renderRoot(`root-${appId}`);
    } else {
      // ReactDOM.render(scripts.root, element);
    }
  }

  public async renderLiveTitle({
    appId,
    bundleUrl,
    element,
  }: {
    appId: string;
    bundleUrl?: string;
    element: HTMLElement;
  }) {
    const scripts = await this.getScriptRender(appId, bundleUrl);
    if (!scripts || !scripts.liveTitle) return;

    if (scripts.renderLiveTitle) {
      scripts.renderLiveTitle(`livetitle-${appId}`);
    } else {
      ReactDOM.render(scripts.liveTitle, element);
    }
  }

  public unmount(element: HTMLElement) {
    ReactDOM.unmountComponentAtNode(element);
  }
}

function loadScript(src: string) {
  return new Promise(function (resolve, reject) {
    var s;
    s = document.createElement("script");
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
}

// get script path
async function getScriptPath(appId: string): Promise<string> {
  const appsInState = getState("apps");
  let data;
  if (appsInState.length === 0) {
    const res = await ListMircroApps().getItems();
    if (res.isError) return "";
    data = res.data;
  } else {
    data = appsInState;
  }
  return data.find((item: any) => item.app_id === appId)?.bundle_url;
}

const Am2 = new AppManager2();
(window as any).AppManager = Am2;
export default Am2;
