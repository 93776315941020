import React from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Box, Flex, Text } from "rebass";
import styled, { css } from "styled-components";
import AppManager2 from "./AppManager";
import { unEyeoff } from "../state";

type Props = {
  pinId: string;
  appId: string;
  bundleUrl?: string;
  name?: string;
  icon?: string;
  external_url?: string;
  eyeoff?: boolean;
};
export default class RenderRoot extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.eyeoff !== this.props.eyeoff) return true;
    return false;
  }
  componentDidMount() {
    setTimeout(() => {
      AppManager2.renderLiveTitle({
        appId: this.props.appId,
        bundleUrl: this.props.bundleUrl,
        element: document.getElementById(`livetitle-${this.props.appId}`)!,
      });
    }, 1);
  }

  // componentWillUnmount() {
  //   if (document.getElementById(`livetitle-${this.props.appId}`)) {
  //     AppManager2.unmount(
  //       document.getElementById(`livetitle-${this.props.appId}`)!
  //     );
  //   }
  // }
  render() {
    return (
      <Box width={["100%", 400]} maxWidth={400} overflowX="auto">
        {this.props.eyeoff && (
          <EyeOffComp onClick={() => unEyeoff(this.props.pinId)}>
            <FiEyeOff size={40} />
          </EyeOffComp>
        )}
        <Link
          to={{ pathname: this.props.external_url || `/${this.props.appId}` }}
          target={this.props.external_url ? "__blank" : ""}
        >
          <Flex py={1} alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <img src={this.props.icon} height={32} />
              <Text color="#eee" fontWeight={600} ml={2}>
                {this.props.name}
              </Text>
            </Flex>
            {/* <Flex color="#555" onClick={e => { e.preventDefault(); unpinApp(this.props.pinId); }}>
              Unpin
            </Flex> */}
          </Flex>
        </Link>
        <LiveTitleOutline
          id={`livetitle-${this.props.appId}`}
          height={400}
          alignItems="center"
          justifyContent="center"
          backgroundColor="#444"
          flexDirection="column"
          eyeoff={this.props.eyeoff}
        >
          <img src={this.props.icon} width={100} />
          <Text color="#eee" fontWeight={600}>
            {this.props.name}
          </Text>
        </LiveTitleOutline>
      </Box>
    );
  }
}

const LiveTitleOutline = styled(Flex)`
  border: 1px solid #rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  overflow: hidden;
  div {
    box-sizing: border-box;
  }
  ${(props: { eyeoff?: boolean }) =>
    props.eyeoff &&
    css`
      filter: blur(8px);
    `}
`;
const EyeOffComp = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  // background: rgba(0,0,0,.8);
  border-radius: 10px;
  z-index: 100;
`;
