import React from "react";
import { Box } from "rebass";
import LoadingRoot from "../components/LoadingRoot";
import AppManager2 from "./AppManager";

type Props = {
  appId: string;
  bundleUrl?: string;
  name?: string;
  icon?: string;
};
export default class RenderRoot extends React.Component<Props> {
  componentDidMount() {
    AppManager2.renderRoot({
      appId: this.props.appId,
      bundleUrl: this.props.bundleUrl,
      element: document.getElementById(`root-${this.props.appId}`)!,
    });
  }

  render() {
    return (
      <Box id={`root-${this.props.appId}`} height="100%" overflow="hidden">
        <LoadingRoot />
        {this.props.name}
      </Box>
    );
  }
}
