import LightState from "react-light-state";
import { STORE_KEYS } from "../consts";

const UserStore = new LightState(
    {
        isAuthened: false,
        userinfo: {},
    },
    "userStore",
    {
        storageName: STORE_KEYS.LIGHT_USER_STORE,
    }
);

export default UserStore;

const { setState, useStore } = UserStore;
export { useStore };

export function loginSuccessed(userlogonInfo: any) {
    setState({ userinfo: userlogonInfo, isAuthened: true });
}
export function logout() {
    setState({ userinfo: {}, isAuthened: false })
}