import React from "react";

export default (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        {...props}
    >
        <g
            id="Group_1"
            data-name="Group 1"
            transform="translate(-16 -8)"
            fill="#888"
        >
            <rect width="8" height="8" rx="2" transform="translate(48 24)" />
            <rect width="8" height="8" rx="2" transform="translate(48 40)" />
            <rect width="8" height="8" rx="2" transform="translate(32 24)" />
            <rect width="8" height="8" rx="2" transform="translate(32 40)" />
            <rect width="8" height="8" rx="2" transform="translate(16 24)" />
            <rect width="8" height="8" rx="2" transform="translate(16 40)" />
            <rect width="8" height="8" rx="2" transform="translate(16 8)" />
            <rect width="8" height="8" rx="2" transform="translate(32 8)" />
            <rect width="8" height="8" rx="2" transform="translate(48 8)" />
        </g>
    </svg>
);
