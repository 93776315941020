import React, { useRef, useState } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import useOnClickOutside from "../../hooks/useClickOutside";

type Props = {
    children: React.ReactChild;
    actions: ActionProps[];
    width?: any;
};
export type ActionProps = {
    title: string;
    onClick: () => void;
    icon?: any;
};
export default function ({ children, actions = [], width }: Props) {
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);
    useOnClickOutside(ref, () => setVisible(false));

    return (
        <Wrapper
            onClick={(e) => {
                setVisible(true);
            }}
        >
            {children}
            {visible && (
                <ActionMenu ref={ref} width={width} variant="action_menu">
                    {actions.map((action, idx) => (
                        <ActionItem
                            p={2}
                            key={idx}
                            alignItems="center"
                            onClick={(e) => {
                                e.stopPropagation();
                                setVisible(false);
                                action.onClick();
                            }}
                        >
                            <Flex
                                width={"auto"}
                                justifyContent="flex-end"
                                mr={2}
                            >
                                {action.icon}
                            </Flex>
                            {action.title}
                        </ActionItem>
                    ))}
                </ActionMenu>
            )}
        </Wrapper>
    );
}

const Wrapper = styled(Box)`
    position: relative;
`;
const ActionMenu = styled(Box)`
    position: absolute;
    right: 0;
    top: 100%;
    border-radius: 10px;
    min-width: 100px;
    border: 1px solid #eee;
    padding: 5px;
`;
const ActionItem = styled(Flex)`
    font-weight: 500;
    border-radius: 5px;
    :hover {
        cursor: pointer;
        background: rgba(100, 100, 100, 0.3);
    }
`;
