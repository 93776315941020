import React from "react";
import { Box, BoxProps } from "rebass";
import styled from "styled-components";

const Base = styled(Box)`
    border-radius: ${(props: any) => (props.rounded !== false ? "50%" : "0")};
    background: url(${(props: any) => props.url});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`;

enum AvatarSize {
    sm = "sm",
    md = "md",
    lg = "lg",
}

type Props = {
    size: AvatarSize;
    url: string;
    rounded?: boolean
} & BoxProps;

const mapSize = (size: AvatarSize): { width: number; height: number } =>
    ({
        [AvatarSize.sm]: {
            width: 30,
            height: 30,
        },
        [AvatarSize.md]: {
            width: 50,
            height: 50,
        },
        [AvatarSize.lg]: {
            width: 80,
            height: 80,
        },
    }[size]);

const Avatar = ({ size = AvatarSize.md, url, ...rest }: Props) => (
    <Base
        {...mapSize(size)}
        // @ts-ignore
        url={url}
        {...rest}
        css={{}}
    />
);

export default Avatar;
export { AvatarSize };
