import { AuthorizedAppWrap, Button, StyleProvider } from "@fozg/fozg-ui-elements";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Flex } from "rebass";
import LoginedLayout from "./components/layouts/LoginedLayout";
import Home from "./pages/Home";
import ViewApp from "./pages/ViewApp";
import Sandbox from "./pages/Sandbox";

function App() {
    return (
        <StyleProvider theme="dark">
            <Router>
                <Switch>
                    <Route path="/share/:id" component={() => <ViewApp appId="share" />}></Route>
                    <Route
                        path="/"
                        component={() => (
                            <LoginedRoute>
                                <Home />
                            </LoginedRoute>
                        )}
                        exact
                    />
                    <Route path="/sandbox/:appId" component={() => <Sandbox />} exact />

                    <Route
                        path="/:appId"
                        component={() => (
                            <LoginedRoute>
                                <ViewApp />
                            </LoginedRoute>
                        )}
                    />
                </Switch>
            </Router>
        </StyleProvider>
    );
}

export default App;

const LoginedRoute: React.FC = ({ children }) => (
    <AuthorizedAppWrap
        customLoginPage={({ buttonLogin: ButtonLogin }) => (
            <Flex height="100vh" alignItems="center" justifyContent="center" flexDirection="column" backgroundColor="#333">
                <img src="/logo.svg" />
                <ButtonLogin />
            </Flex>
        )}
    >
        <LoginedLayout>{children}</LoginedLayout>
    </AuthorizedAppWrap>
);
