import OnePoint from "@fozg/one-point-sdk";

export const OPService = () =>
  new OnePoint(
    "https://fozg.net/opapi",
    localStorage.getItem("token")!
  ).getAppByName("fozg-elements");

export const ListMircroApps = () => OPService().getListByName("micro_apps");
export const ListUserPins = () =>
  OPService().getListByName("micro_apps_user_pin");

(window as any).OnePoint = () => new OnePoint("https://fozg.net/opapi", localStorage.getItem("token")!);
