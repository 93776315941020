import { Link } from "react-router-dom";
import { Flex, Text } from "rebass";
import styled from "styled-components";

type Props = {
  icon_url: string;
  name: string;
  app_id: string;
};

export default function ({ icon_url, name, app_id }: Props) {
  return (
    <LinkStyled to={`/${app_id}`}>
      <Flex alignItems="center">
        <Icon src={icon_url}></Icon>
        <Text color="#eee" fontWeight="600" ml={1}>
          {name}
        </Text>
      </Flex>
    </LinkStyled>
  );
}

const Icon = styled.img`
  width: 50px;
  height: 50px;
`;
const LinkStyled = styled(Link)`
  border-radius: 9px;
  padding: 10px;
  width: 100%;
  display: block;
  transition: background 0.1s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
