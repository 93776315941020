export const isDev = process.env.NODE_ENV !== "production";

const API_BASEPATH = "https://api.fozg.net/";
export default {
    USER_INFO_STORAGE_KEY: "USERINFO_Todomorrow",
    API_CURRENT_USER: API_BASEPATH + "me",
    TOKEN_KEY: "token"
};

export const STORE_KEYS = {
    LIGHT_USER_STORE: "LIGHT_USER_STORE",
};
