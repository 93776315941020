import { Box, Flex, Text } from "rebass";
import TopNavigation from "../TopNavigation";
import { Link, useHistory, useLocation } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import styled from "styled-components";
import Logo from "./Logo";
import { useStore } from "../../state";
import { useEffect } from "react";

export default ({ children }: { children: any }) => {
    return (
        <Box>
            <TopNavigation
                icon={
                    <Link to="/">
                        <Flex alignItems="center">
                            <Logo height={30} width={30} />

                            <AppLogo />
                        </Flex>
                    </Link>
                }
            ></TopNavigation>
            <Box height="calc(100vh - 50px)">{children}</Box>
        </Box>
    );
};

const setSiteIcon = (icon_url: string) => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement("link");
        // @ts-ignore
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
    }
    (link as any).href = icon_url;
};
const AppLogo = () => {
    const apps = useStore((state) => state.apps);
    const location = useLocation();
    const [_, appName] = location.pathname.split("/");
    const app: any = apps.find((app: any) => app.app_id === appName);
    useEffect(() => {
        if (location.pathname === "/") {
            document.title = "Dashboard";
            setSiteIcon("/logo.svg");
        }
        if (app) {
            document.title = app?.name;
            setSiteIcon(app?.icon_url);
        }
    }, [location]);

    if (!app)
        return (
            <Text fontWeight={600} ml={1}>
                Dashboard
            </Text>
        );
    return (
        <CustomFlex alignItems="center">
            {app && <IoIosArrowRoundForward size={30} />}
            <LinkStyled to={`/${app.app_id}`}>
                {app && <LogoStyled src={app.icon_url} />}
                <Text>{app?.name}</Text>
            </LinkStyled>
        </CustomFlex>
    );
};
const CustomFlex = styled(Flex)``
const LogoStyled = styled.img`
    width: 30px;
    height: 30px;
`;
const LinkStyled = styled(Link)`
    display: flex;
    align-items: center;
`;
