import React, { ReactElement, useState, useRef } from "react";
import { Box, Flex, Text } from "rebass";
import styled, { keyframes } from "styled-components";
import { logout, useStore } from "../Authentication/lightState/UserStore";
import Avatar, { AvatarSize } from "../Avatar";
import Grid from "../../icons/Grid";
import AppLists from "./AppLists";
import useOnClickOutside from "../../hooks/useClickOutside";
import ActionMenu from "../ActionMenu";

type Props = {
    icon: ReactElement;
    buttonDarkmodeVisible?: boolean;
};
export default function ({ icon, buttonDarkmodeVisible }: Props) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <TopNavigation alignItems="center" variant="top_navigation">
            <AppsIcon onClick={() => setDrawerOpen(true)}>
                <Grid width={18} />
            </AppsIcon>
            {drawerOpen && (
                <DrawerPannel onClose={() => setDrawerOpen(false)} />
            )}
            {icon}
            <Box flex={1}></Box>
            <TopRight>
           
                <ActionMenu
                    actions={[
                        {
                            title: "Your profile",
                            onClick: () => {
                                window.location.href =
                                    "https://accounts.fozg.net/";
                            },
                        },
                        {
                            title: "Logout",
                            onClick: () => {
                                logout();
                            },
                        },
                    ]}
                    width={160}
                >
                    <UserProfile />
                </ActionMenu>
            </TopRight>
        </TopNavigation>
    );
}

const DrawerPannel = ({ onClose }: any) => {
    const ref = useRef(null);

    useOnClickOutside(ref, () => onClose());

    return (
        <Drawer ref={ref} variant="drawer" minWidth={360}>
            <AppLists />
        </Drawer>
    );
};

const UserProfile = () => {
    const userinfo: any = useStore((state: any) => state.userinfo);
    return (
        <UserProfileWrap>
            <Text fontSize={13} fontWeight={500}>
                {userinfo.name}
            </Text>
            <Avatar size={AvatarSize.sm} url={userinfo.avatar_url} ml={2} />
        </UserProfileWrap>
    );
};

const TopNavigation = styled(Flex)`
    height: 50px;
   
    box-sizing: border-box;
`;
const AppsIcon = styled.div`
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        background: rgba(150, 150, 150, 0.2);
    }
`;
const TopRight = styled(Flex)`
    padding: 0 10px;
    align-items: center;
`;
const UserProfileWrap = styled.div`
    height: 50px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    :hover {
        background: rgba(150, 150, 150, 0.3);
    }
`;
const drawerFadeIn = keyframes`
    0% {
        opacity: 0;
        // height: 80%;
        width: 300px;
    }
    100% {
        opacity: 100;
        // height: 100%;
        width: 400px;   
    }
`;
const Drawer = styled(Box)`
    position: fixed;
    left: 10px;
    top: 45px;
    border-radius: 15px;
    z-index: 999;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    animation: ${drawerFadeIn} 0.3s;
    overflow-x: hidden;
    padding: 20px;
`;
const ButtonDarkmode = styled.div`
    font-size: 13px;
    background: rgba(100, 100, 100, 0.3);
    border-radius: 10px;
    font-weight: 600;
    border-right: 3px solid yellow;
    padding: 1px 5px;
`;
