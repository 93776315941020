import LightState from "react-light-state";
import { ListMircroApps, ListUserPins } from "./services/OnePointService";

const initState = {
    apps: [
        {
            app_id: "share",
            bundle_url: "https://op.fozg.dev/micro/share.js",
            created: "2021-09-21T16:05:19.352Z",
            createdBy: "fozg",
            external_url: null,
            icon_url: "https://cdn.fozg.dev/md/ee433692-2adc-4908-873f-881b58c7673a.png",
            list: "6045f44e95d827143b028acc",
            name: "Share",
            updated: "2021-09-21T16:05:19.352Z",
            updatedBy: "fozg",
        },
        {
            app_id: "cms",
            bundle_url: "https://op.fozg.dev/micro/cms.js",
            created: "2021-09-08T16:07:53.597Z",
            createdBy: "fozg",
            external_url: null,
            icon_url: "https://static.fozg.net/cms/cms.svg",
            list: "6045f44e95d827143b028acc",
            name: "CMS",
            pin_home_page: null,
            shareTeams: [],
            updated: "2021-09-08T16:07:53.597Z",
            updatedBy: "fozg",
        },
    ],
    micro_apps_user_pin: [],
    eyeoff: [],
};

const store = new LightState<{
    eyeoff: string[];
    apps: any[];
    micro_apps_user_pin: any[];
}>(initState, "fozgDotNetStore", {
    storageName: "fdnst",
    // @ts-ignore
    getFromStorage: (storeName: string) => {
        try {
            return {
                ...initState,
                ...JSON.parse(localStorage.getItem(storeName) || "{}"),
            };
        } catch {
            return initState;
        }
    },
});

const { setState, getState, useStore } = store;
export { useStore, getState };

export const fetchApps = async () => {
    const res = await ListMircroApps().getItems();
    if (!res.isError) {
        setState({ apps: res.data });
    }
};
export const fetchUserPinApps = async () => {
    const res = await ListUserPins().getItems();
    if (!res.isError) {
        setState({ micro_apps_user_pin: res.data });
    }
};
export const unpinApp = async (_id: string) => {
    const res = await ListUserPins().deleteItem(_id);
    if (!res.isError) {
        fetchUserPinApps();
    }
};
export const eyeoffApp = (_id: string) => {
    setState((state) => {
        const eyeoff = [...state.eyeoff];
        eyeoff.push(_id);
        return { eyeoff };
    });
};
export const unEyeoff = (_id: string) => {
    setState((state) => {
        let eyeoff = [...state.eyeoff];
        eyeoff = eyeoff.filter((item) => item !== _id);
        return { eyeoff };
    });
};
