import { useParams } from "react-router";
import RenderRoot from "../core/RenderRootApp";

export default function ({appId}: {appId?: string}) {
  const params = useParams<{ appId: string }>();

  /**
   * Why we need <iframe> here?
   * Cause some app that overwrite global style so that we need to push them into a iframe, in that case we call it `SandBox`
   * Hard code for now some apps that overwrited so hard.
   */
  if (params.appId === "codepins")
    return (
      <iframe
        src={`/sandbox/${params.appId}`}
        height="auto"
        style={{ border: "none", width: "100%", height: "100%" }}
      ></iframe>
    );

  return <RenderRoot appId={params.appId || appId!}></RenderRoot>;
}
