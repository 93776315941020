import { ActionMenu, Button, ButtonVariants } from "@fozg/fozg-ui-elements";
import { useEffect } from "react";
import { Box, Flex, Text } from "rebass";
import styled from "styled-components";
import { FiEyeOff, FiMoreHorizontal } from "react-icons/fi";
import RenderLiveTitle from "../core/RenderLiveTitle";
import {
  useStore,
  fetchApps,
  fetchUserPinApps,
  unpinApp,
  eyeoffApp,
} from "../state";
import AppItem from "../components/AppItem";

export default function () {
  const { apps, micro_apps_user_pin, eyeoff } = useStore((state) => state);

  useEffect(() => {
    fetchApps();
    fetchUserPinApps();
  }, []);
  return (
    <Box pb={5} backgroundColor="#0e1112">
      <LiveTiteSection>
        <Flex
          flexWrap="wrap"
          key={micro_apps_user_pin.length}
          justifyContent="center"
        >
          {micro_apps_user_pin.map((item: any, idx: number) => (
            <AppThumbnail key={item.app_id} m={[1,2,3]} width={400}>
              <RenderLiveTitle
                key={item.app_id}
                appId={item.micro_apps?.app_id}
                bundleUrl={item.micro_apps?.bundle_url}
                name={item.micro_apps?.name}
                icon={item.micro_apps?.icon_url}
                {...item.micro_apps}
                pinId={item._id}
                eyeoff={eyeoff.find((e) => item._id === e)}
              ></RenderLiveTitle>
              <GoTo>
                <ActionMenu
                  actions={[
                    {
                      title: "Un pins",
                      onClick: () => {
                        unpinApp(item._id);
                      },
                    },
                    {
                      title: "Eyeoff",
                      icon: FiEyeOff,
                      onClick: () => {
                        eyeoffApp(item._id);
                      },
                    },
                  ]}
                  width={140}
                >
                  <Button color="#fff" variant={ButtonVariants.outline}>
                    <FiMoreHorizontal />
                  </Button>
                </ActionMenu>
              </GoTo>
            </AppThumbnail>
          ))}
        </Flex>
      </LiveTiteSection>
      <MoreAppsSection
        mx={"auto"}
        mt={"-50px"}
        width={["100%", "100%", "100%", 1600]}
        p={4}
        pt={3}
      >
        <Text color="#ddd" fontWeight="600" mb={3} textAlign="center">
          More apps
        </Text>
        <Flex flexWrap="wrap">
          {apps.map((app: any, idx) => (
            <Box width={["100%", "50%", "25%"]}>
              <AppItem
                name={app.name}
                icon_url={app.icon_url}
                key={app._id}
                app_id={app.app_id}
              />
            </Box>
          ))}
        </Flex>
      </MoreAppsSection>
    </Box>
  );
}

const LiveTiteSection = styled.div`
  // background: url(https://images.unsplash.com/photo-1611477407986-7c3778593a61?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2704&q=100);
  background: #292929;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 20px 0;
  padding-bottom: 150px;
  min-height: calc(100vh - 50px);
  border-bottom: 2px solid #111;
`;

const GoTo = styled.div`
  position: absolute;
  display: none;
  right: 0px;
  top: 10px;
  z-index: 10;
  color: #fff;
`;

const AppThumbnail = styled(Box)`
  // border: 1px solid #111;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  :hover {
    ${GoTo} {
      display: block;
    }
  }
`;

const MoreAppsSection = styled(Box)`
  max-width: 1000px;
  background: #1c1d19;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #111;
  @media only screen and (max-width: 600px) {
    border-radius: 0;
    border: none;
  } 
`;
